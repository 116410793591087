import axios, { AxiosRequestConfig } from "axios";
import LocalStorageService from "../local-storage/local-storage.service";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
abstract class AbstractService<T> {
    abstract API_SEGMENT: string;
    protected axios = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

    constructor() {
        this.axios.interceptors.request.use(this.authInterceptor);
    }

    private authInterceptor(config: AxiosRequestConfig) {
        const token = LocalStorageService.accessToken;

        if (token) {
            config.headers.authorization = `Bearer ${token}`;
        }

        return config;
    }
}

export default AbstractService;
