import { useRef, useState } from "react";

export function useVideoPlayer() {
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const [playing, setPlaying] = useState(false);

    const handlePlayClick = () => {
        if(!videoRef?.current) return;

        videoRef.current.play();
        audioRef?.current?.play();
    }

    const handlePlay = () => {
        setPlaying(true);
    }

    return { videoRef, audioRef, playing, handlePlay, handlePlayClick };
}
