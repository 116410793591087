import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {CloseWhiteIcon, FinalIcon} from "../../assets/icons";
import VigoButton from "../vigo-button/vigo-button.component";
import useAuth from "../../hooks/useAuth/useAuth";
import useWordGames from "../../hooks/useWordGames/useWordGames";
import WordGameResponse from "../../types/models/word-game-response";
import WordGame from "../../types/models/word-game";
import { useTranslation } from "react-i18next";
import {ErrorModal} from "../vigo-modals";

const QuestionComponent = (props: any) => {
  const { user } = useAuth();
  const wordGames = useWordGames(props.message.speechGame, user.localeId);

  const [data, setData] = useState<WordGame[]>([]);
  const [index, setIndex] = useState(-1);
  const [checkedData, setCheckedData] = useState<WordGameResponse[]>([]);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (wordGames.data && wordGames.data.length) {
      setData(wordGames.data);
      setIndex(0);
    } else if (wordGames.error) {
      setErrorMessage(`${wordGames.error}`);
      setError(true)
    }
  }, [wordGames.data, wordGames.error]);

  useEffect(() => {
    setCheckedData([]);
  }, [index]);

  const playAudioResponse = (isCorrect: boolean) => {
    const scr = isCorrect
      ? "https://storage.googleapis.com/vigoaudios/wordgame_correct.mp3"
      : "https://storage.googleapis.com/vigoaudios/wordgame_incorrect.mp3";
    const audio = new Audio(scr);
    audio.play();
  };

  const resetQuestions = () => {
    wordGames.refetch();
    setIndex(-1);
  };

  const checkResponse = (r: WordGameResponse) => {
    if (!checkedData.includes(r) && !checkedData.find((x) => x.isCorrect)) {
      playAudioResponse(r.isCorrect);
      setCheckedData([...checkedData, r]);
      if (r.isCorrect) {
        setTimeout(() => {
          setIndex(index + 1);
        }, 3000);
      }
    }
  };

  return index < 0 ? (
      <>
        <ErrorModal setModal={setError}
                    modal={error}
                    text={errorMessage} />

        <button
            className="absolute z-80 rounded-full top-10 right-10 bg-dark py-4 px-4 opacity-80"
            onClick={() => props.setOpen(false)}
        >
          <img src={CloseWhiteIcon} className="w-8 h-8 " alt="close" />
        </button>
      </>
  ) : index < data.length ? (
    <div className="flex flex-col justify-center py-8">
      <img
        className="rounded-t-2xl w-7/12 self-center max-h-96 object-cover "
        src={data[index].image}
        alt="alt question"
      />
      <div className="w-7/12 py-2 self-center">
        <p id="text" className="font-regular rounded-bl-2xl shadow-3xl py-4 px-4">
          {data[index].description}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4 self-center py-2 w-7/12 ">
        {data[index].responses.map((r: WordGameResponse, index) => (
          <VigoButton
            key={index}
            className={checkedData.includes(r) ? (r.isCorrect ? "correctAnswer animate-pulse" : "incorrectAnswer") : ""}
            appearance="active"
            onClick={() => checkResponse(r)}
          >
            {r.title}
          </VigoButton>
        ))}
      </div>
    </div>
  ) : (
    <FinalComponent resetQuestions={resetQuestions} setOpen={props.setOpen} />
  );
};

const FinalComponent = (props: any) => {
  const { t } = useTranslation();
  const audioSrc = "https://storage.googleapis.com/vigoaudios/wordgame_gameover.mp3";

  return (
    <>
      <audio src={audioSrc} autoPlay />
      <div className="flex flex-col items-center justify-center h-screen ">
        <img className="rounded-t-2xl max-w-md self-center" src={FinalIcon} alt="Final Icon" />
        <div className="w-7/12 py-2 self-center">
          <p id="text" className="font-bold text-center text-3xl py-4 px-4">
            {t("game.success.title")}
          </p>
          <p className="py-4 text-center">{t(`game.success.message.${Math.floor(Math.random() * 7)}`)}</p>
        </div>
        <div className="grid grid-cols-2 gap-4 self-center py-2 w-7/12 ">
          <VigoButton appearance="active" onClick={props.resetQuestions}>
            {t("game.success.button.title.0")}
          </VigoButton>
          <VigoButton appearance="active" onClick={() => props.setOpen(false)}>
            {t("game.success.button.title.1")}
          </VigoButton>
        </div>
      </div>
    </>
  );
};

function SpeechGame(props: any) {
  const cancelButtonRef = useRef(null);

  return (
    <div>
      <div className="">
        <Transition.Root show={props.open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-50 inset-0 overflow-y-auto"
            initialFocus={cancelButtonRef}
            onClose={() => props.setOpen(false)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-105 transition-opacity" />
              </Transition.Child>
              {/* trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white text-left overflow-hidden transform transition-all h-screen w-full">
                  <QuestionComponent message={props.message} setOpen={props.setOpen} />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}

export default SpeechGame;
