import Answer from "../../types/models/answer";
import { useState } from "react";

const AnswerQuiz = (props: any) => {
  const [checkedData, setCheckedData] = useState<Answer[]>([]);

  const playAudioResponse = (isCorrect: boolean) => {
    const scr = isCorrect
      ? "https://storage.googleapis.com/vigoaudios/wordgame_correct.mp3"
      : "https://storage.googleapis.com/vigoaudios/wordgame_incorrect.mp3";
    const audio = new Audio(scr);
    audio.play();
  };

  const checkAnswer = (a: Answer) => {
    if (!checkedData.includes(a)) {
      playAudioResponse(a.isCorrect);
      const newData = [...checkedData, a];
      setCheckedData(newData);
      if (!props.answer.quiz.find((x: Answer) => !newData.includes(x) && x.isCorrect)) {
        props.handleAnswer(a);
        props.handleOutgoing(props.answer);
      }
    }
  };

  if (props.isOutgoing) {
    const correctAnswers = props.answer.quiz.filter((x: Answer) => x.isCorrect);
    return (
      <div className="grid grid-cols-2 gap-2 py-2">
        {correctAnswers.map((item: any, index: any) => {
          return (
            <div key={index}>
              <button
                className="w-full text-2xl bg-active font-regular rounded-xl shadow-3xl py-3 px-4 text-center mb-2 cursor-pointer"
                style={{ backgroundColor: "#00B87C" }}
              >
                <span className="text-white" style={{ fontSize: `${props.textSize}rem` }}>
                  {item.phrase}
                </span>
              </button>
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <div>
        <div className="grid grid-cols-2 gap-2 py-2">
          {props.answer.quiz.map((item: Answer, index: any) => {
            return (
              <div key={index}>
                <button
                  key={index}
                  className="w-full text-2xl bg-active font-regular rounded-xl shadow-3xl py-3 px-4 text-center mb-2 cursor-pointer"
                  style={
                    checkedData.includes(item)
                      ? item.isCorrect
                        ? { backgroundColor: "#00B87C" }
                        : { backgroundColor: "#FF0000" }
                      : {}
                  }
                  onClick={() => {
                    checkAnswer(item);
                  }}
                >
                  <span className="text-white" style={{ fontSize: `${props.textSize}rem` }}>
                    {item.phrase}
                  </span>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
};

export default AnswerQuiz;
